// extracted by mini-css-extract-plugin
export var bgTxt = "solution2-0-module--bgTxt--qyJUy";
export var body = "solution2-0-module--body--JYUvS";
export var content = "solution2-0-module--content--EZu8b";
export var genealogy = "solution2-0-module--genealogy--uBLb+";
export var head = "solution2-0-module--head---g7zp";
export var img = "solution2-0-module--img--olNZ-";
export var inner = "solution2-0-module--inner--ZUTN9";
export var item = "solution2-0-module--item--Ce1rf";
export var label = "solution2-0-module--label--YIfS+";
export var lead = "solution2-0-module--lead--AeylH";
export var list = "solution2-0-module--list---s92a";
export var pillars = "solution2-0-module--pillars--TmPYW";
export var service = "solution2-0-module--service--RTjiA";
export var subtitle = "solution2-0-module--subtitle--T8lD8";
export var top = "solution2-0-module--top--3XsNz";