import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Information from "../components/information"

import IMG_01 from "../images/service-01.png"
import IMG_02 from "../images/service-02.png"
import IMG_03 from "../images/service-03.png"

import * as styles from "../styles/solution2.0.module.scss"

const Solution2 = () => {
  return (
    <Layout>
      <Seo title="サービス(WEB2.0)" description="このページは「サービス(WEB3.0)」に関するページです。" />
      <section className="cPageHeader">
        <div className="inner cContainer">
          <div className="content">
            <h2>
              <span className="alphabet">SOLUTION2.0</span><br />
              WEB2.0対応サービス
            </h2>
          </div>
        </div>
      </section>

      <section className={styles.service}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.genealogy}>
              <div className={styles.top}>
                <p className={`${styles.bgTxt} alphabet`}>solution</p>
                <div className={`${styles.lead} alphabet`}>WEB2.0</div><br/>
                マーケティング事業
              </div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={IMG_01}></img>
                    <p className={styles.label}>フロントエンド</p>
                  </div>
                  <p>
                    LP<br />
                    コーポレート<br />
                    ブランディング
                  </p>
                </div>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={IMG_02}></img>
                    <p className={styles.label}>バックエンド</p>
                  </div>
                  <p>
                    CRM開発<br/>
                    ECサイト開発<br/>
                    Shopify開発
                  </p>
                </div>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={IMG_03}></img>
                    <p className={styles.label}>マーケティング</p>
                  </div>
                  <p>
                    UI／UX改善<br/>
                    リスティング広告<br/>
                    SNS広告
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.pillars}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <div className={`${styles.subtitle} alphabet`}>pillars</div>
              <h2>サポートできる技術</h2>
            </div>
            <div className={styles.body}>
              <ul className={styles.pillars}>
                <li>WEBサイト制作</li>
                <li>EC開発</li>
                <li>Shopify開発</li>
                <li>WordPressテーマ制作</li>
                <li>メディアサイト</li>
                <li>ランディングページ</li>
                <li>アクセス解析</li>
                <li>リスティング広告</li>
                <li>SNS広告</li>
                <li>UI／UX改善</li>
                <li>CRM開発</li>
                <li style={{ visibility: 'hidden' }}></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Information />
      
    </Layout>
  )
}

export default Solution2